import React from 'react';
import SEO from 'components/seo';

// Component
import TextBlock from 'components/organisms/Blocks/TextBlock/TextBlock';

// Data
import ContactData from 'data/ContactData/ContactData.json';

const ContactBedanktPage = () => (
  <>
    <SEO title="Contact" />
    <TextBlock
      data={ContactData.contactThanksInfo}
    />
  </>
);

export default ContactBedanktPage;
